import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { mergeMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

export function tokenInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  const authService = inject(AuthService);
  return req.headers.has('BypassTokenCheck')
    ? next(
        req.clone({
          url: environment.baseURL + req.url,
          setHeaders: {
            'Content-Type': 'application/json'
          }
        })
      )
    : authService.getTokenSilently$().pipe(
        mergeMap(token => {
          let tokenReq = req.clone({
            url: environment.baseURL + req.url,
            setHeaders: {
              Authorization: token.access_token,
              'Content-Type': 'application/json'
            }
          });
          return next(tokenReq);
        })
      );
}
