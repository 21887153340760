import { ApplicationRef, Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, first, interval } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { addBreadcrumb, captureException } from '@sentry/angular';
import { SentryCategory } from '../models/sentry.model';
@Injectable({
  providedIn: 'root'
})
export class PwaService {
  constructor(
    private swUpdate: SwUpdate,
    private appRef: ApplicationRef,
    private snackbar: MatSnackBar
  ) {
    const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
    const everyHour$ = interval(1 * 60 * 60 * 1000);
    const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);

    everyHourOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await swUpdate.checkForUpdate();
        if (updateFound) {
          addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Update Found',
            type: 'info',
            level: 'info'
          });
        }
      } catch (err) {
        captureException(err);
      }

      swUpdate.unrecoverable.subscribe(event => {
        captureException(event.reason);
        this.snackbar.open(
          'An error has occurred that requires a page reload. The page will be refreshed in 5 seconds.',
          null,
          {
            panelClass: 'error-toast'
          }
        );
        setTimeout(() => {
          document.location.reload();
        }, 5000);
      });
    });

    this.swUpdate.versionUpdates.subscribe(evt => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version detected',
            type: 'info',
            level: 'info',
            data: {
              version: evt
            }
          });
          break;
        case 'VERSION_READY':
          addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version Ready',
            type: 'info',
            level: 'info',
            data: {
              currentVersion: evt.currentVersion.hash,
              latestVersion: evt.latestVersion.hash
            }
          });
          document.location.reload();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          addBreadcrumb({
            category: SentryCategory.serviceWorker,
            message: 'Version Installation Failed',
            type: 'info',
            level: 'info',
            data: {
              version: evt.version.hash
            }
          });
          captureException(new Error(`Failed to install app version '${evt.version.hash}': ${evt.error}`));
          break;
      }
    });
  }
}
