import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { browserTracingIntegration, init, replayIntegration } from '@sentry/angular';
if (environment.develop || environment.qa || environment.uat || environment.production) {
  init({
    dsn: 'https://bc25276dea36047a740296ad178c56a8@o4504106901176320.ingest.us.sentry.io/4507504634822656',
    environment: environment.environment,
    replaysSessionSampleRate: 0.5,
    replaysOnErrorSampleRate: 1.0,
    integrations: [replayIntegration(), browserTracingIntegration()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.25
  });
}
if (environment.production || environment.uat || environment.qa || environment.develop) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));
