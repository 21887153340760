import { HttpHandlerFn, HttpRequest } from '@angular/common/http';

export function multiPartInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn) {
  return req.headers.has('multipart')
    ? next(
        req.clone({
          headers: req.headers.delete('Content-Type')
        })
      )
    : next(req);
}
